<template>
    <div class="icon-item-group">
        <t-dropdown
            :maxColumnWidth="120"
            :options="dropdownOptions"
            trigger="hover"
            :hideAfterItemClick="false"
        >
            <div class="icon-item">
                <div class="icon-item-base">
                    <img v-lazy="itemData.base64Url" />
                </div>
                <div class="icon-item-name">{{ itemData.title }}</div>
            </div>
        </t-dropdown>
    </div>
</template>

<script>
import {
    toPascalCase,
    toKebabCase,
    base64ToBlob,
    generatePngDataUrl,
    downloadFile,
} from "../utils";
import { isArray } from "lodash";
export default {
    props: {
        itemData: {
            type: Object,
            default: () => {},
        },
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            dropdownOptions: [
                {
                    content: "复制SVG",
                    value: 1,
                    onClick: this.copySvg,
                },
                {
                    content: "复制React代码",
                    value: 2,
                    onClick: this.copyReact,
                },
                {
                    content: "复制Vue代码",
                    value: 3,
                    onClick: this.copyVue,
                },
                {
                    content: "复制PNG",
                    value: 4,
                    divider: true,
                    onClick: this.copyPng,
                },
                {
                    content: "下载SVG",
                    value: 5,
                    onClick: this.downloadSvg,
                },
                {
                    content: "下载PNG",
                    value: 6,
                    onClick: this.downloadPng,
                },
            ],
        };
    },
    computed: {
        svgContent() {
            return this.itemData.svg || "";
        },
        iconName() {
            return this.itemData.name || "";
        },
    },
    methods: {
        getIconComp(type = "React") {
            const validTypes = ["React", "Vue"];
            if (!validTypes.includes(type)) {
                throw new Error("type must be one of React or Vue");
            }

            const {
                theme,
                colors,
                size,
                strokeLinecap,
                strokeLinejoin,
                strokeWidth,
            } = this.config;

            const iconName =
                type === "React"
                    ? toPascalCase(this.iconName)
                    : toKebabCase(this.iconName);

            // 处理 fill 属性
            const fill = colors && colors.length > 0 ? colors : [colors[0]];
            const fillAttr = isArray(fill)
                ? type === "React"
                    ? `fill={[${fill.join(",")}]}`
                    : `:fill="[${fill.join(",")}]"`
                : `fill="${fill}"`;

            // 处理 strokeWidth 属性
            const strokeWidthAttr =
                strokeWidth === 4
                    ? ""
                    : type === "React"
                    ? `strokeWidth={${strokeWidth}}`
                    : `strokeWidth="${strokeWidth}"`;

            // 处理 strokeLinecap 和 strokeLinejoin 属性
            const strokeLinecapAttr =
                strokeLinecap !== "butt"
                    ? `strokeLinecap="${strokeLinecap}"`
                    : "";
            const strokeLinejoinAttr =
                strokeLinejoin !== "miter"
                    ? `strokeLinejoin="${strokeLinejoin}"`
                    : "";

            // 生成最终的组件字符串
            return `<${iconName} theme="${theme}" size="${size}" ${fillAttr} ${strokeWidthAttr} ${strokeLinecapAttr} ${strokeLinejoinAttr} />`;
        },
        copyFun(text = "", showText = false) {
            this.$copyText(text).then(
                () => {
                    this.$message.success(
                        `操作成功${showText ? `：${text}` : ""}`
                    );
                },
                () => {
                    this.$message.info("操作失败");
                }
            );
        },
        copySvg() {
            const copyText = `<?xml version="1.0" encoding="UTF-8"?>${this.svgContent}`;
            this.copyFun(copyText);
        },
        copyReact() {
            const reactCode = this.getIconComp("React");
            this.copyFun(reactCode, true);
        },
        copyVue() {
            const vueCode = this.getIconComp("Vue");
            this.copyFun(vueCode, true);
        },
        async copyPng() {
            const pngDataUrl = await generatePngDataUrl(
                this.itemData.base64Url,
                240,
                240
            );
            const imageBlob = base64ToBlob(pngDataUrl, "image/png");
            this.$copyText(" ").then(() => {
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            "image/png": imageBlob,
                        }),
                    ])
                    .then(() => {
                        this.$message.success(`操作成功`);
                    })
                    .catch((error) => {
                        console.error(
                            "Error copying image to clipboard:",
                            error
                        );
                    });
            });
        },
        async downloadSvg() {
            const mimeType = "image/svg+xml";
            const dataUrl = this.itemData.base64Url;
            await this.setDownload(dataUrl, mimeType);
        },
        async downloadPng() {
            const mimeType = "image/png";
            const dataUrl = await generatePngDataUrl(this.itemData.base64Url);
            await this.setDownload(dataUrl, mimeType);
        },
        async setDownload(dataUrl, mimeType) {
            const blob = base64ToBlob(dataUrl, mimeType);
            const url = URL.createObjectURL(blob);
            const fileName = `${this.itemData.title}_${this.itemData.name}`;
            await downloadFile(url, fileName, mimeType);
            this.$message.success(`操作成功`);
        },
    },
};
</script>

<style lang="stylus" scoped>
.icon-item-group {
    --td-bg-color-secondarycontainer: tarnsparent;
    margin-bottom: 20px;
    padding: 0 10px;
    // width: 12.5%;
    box-sizing: border-box;
    .icon-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width : 120px;
        height: 120px;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 2px;
        &-base{
            width: 48px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width:100%;
                height:auto;
            }
        }
        &-name {
            font-size: 14px;
            color: rgba(0,0,0,0.65);
            font-weight: 500;
        }
        &:hover{
            box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
        }
        &.active{
            border-color: $homeMenuHoverColor;
        }
    }
}
</style>
